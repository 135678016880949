import React, { Component } from "react";
import axios from "@/axios";
import { applyVueInReact } from "vuereact-combined";
import LoadingSpinner from "@/components/LoadingSpinner";
import Payment from "../../components/payment/Payment";

class MaxTv extends Component {
  //using vue component in react.
  LoadingSpinnerComp = applyVueInReact(LoadingSpinner);

  constructor() {
    super();
    this.state = {
      loading: true,
      CustomerId: "",
      reports: "",
      banks: "",
      message: "",
      bank: "",
      paymentModel: false,
      pinVerified: false,
      serviceId: "",
      reward: 0,
      pin: "",
    };
  }

  componentDidMount() {
    this.setState({ loading: false });
  }

  checkMaxTvAccount = async (event) => {
    event.preventDefault();

    if (this.state.CustomerId.length === 0) return;
    this.setState({ loading: true });

    const data = await axios.post("api/v2/services/maxtv/checkAccount", {
      CustomerId: this.state.CustomerId,
    });
    this.setState({ loading: false });
    this.setState({ message: "" });
    if (data.data && data.data.response && data.data.response.Code === "000") {
      this.setState({ reports: data.data.response });
    }
    if (
      (data.data && data.data.response && data.data.response.Code === "029") ||
      (data.data && data.data.response && data.data.response.Code === "026")
    ) {
      this.setState({ message: data.data.response.Message });
    }
  };

  handleChange = (event) => {
    this.setState({ CustomerId: event.target.value.trim() });
  };

  render() {
    return (
      <div className="container">
        <div className="wallet-form-box card">
          <div className="card-body">
            <a href="/" className="btn btn-close">
              <i className="fa fa-times"></i>
            </a>
            <h4> MaxTv</h4>
            <div className="form-group">
              {this.state.message ? (
                <div className="show-message">{this.state.message}</div>
              ) : (
                <></>
              )}
            </div>
            <div className="row">
              <div className="col-md-7">
                {this.state.reports ? (
                  //If reports
                  <div>
                    <div className="mt-4">
                      <div className="font-weight-bold">Customer Name</div>
                      <div>{this.state.reports.CustomerName}</div>
                    </div>
                    <div className="mt-2">
                      <div className="font-weight-bold">CustomerId</div>
                      <div>{this.state.reports.CustomerId}</div>
                    </div>
                    <div className="mt-2">
                      <div className="font-weight-bold">Number of Tv</div>
                      <div>{this.state.reports.NoOfTv}</div>
                    </div>
                    <div className="mt-2">
                      <div className="font-weight-bold">List of Tv</div>
                      <ul>
                        {this.state.reports.TvLists.map((tv) => (
                          <li key={tv.StbNo}> {tv.StbNo} </li>
                        ))}
                      </ul>
                    </div>
                    <div className="mt-2">
                      <div className="font-weight-bold">Bill Amount</div>
                      <div> {this.state.reports.BillAmount} NPR</div>
                    </div>
                    {/* Payment Division */}
                    <div className="fade-animation">
                      <Payment
                        paymentUri="api/v4/maxTv/payment"
                        paymentPayload={{
                          CustomerId: this.state.CustomerId,
                          Amount: this.state.reports.BillAmount,
                        }}
                        setMessage={(message) => {
                          this.setState({ message });
                        }}
                        paymentPreviewDetail={{
                          "Customer Name": this.state.reports.CustomerName,
                          "Total Amount to Pay":
                            "Rs. " + this.state.reports.BillAmount,
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  // If no reports
                  <form>
                    <div className="form-group">
                      <label>
                        {" "}
                        Customer Id <i className="fa fa-asterisk"></i>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Type CustomerId and press enter."
                        value={this.state.CustomerId}
                        onChange={this.handleChange}
                      />
                      <button
                        className="btn btn-primary mt-3"
                        onClick={this.checkMaxTvAccount}
                      >
                        Search
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
            <this.LoadingSpinnerComp loading={this.state.loading} />
          </div>
        </div>
      </div>
    );
  }
}

export default MaxTv;
